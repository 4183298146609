import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
import DrawSVGPlugin from '../lib/DrawSVGPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin);

import Swiper from 'swiper/bundle';

import Muuri from 'muuri';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
	}
};

const body = $('body');
let
delayHeight,
winH = $(window).height(),
winW = $(window).width(),
footer_pos = 0,
navH = 0;

let tar_offset = document.querySelector('#header_nav').offsetHeight;

function nav_show(){
	body.addClass('navopen');
	body.removeClass('navclose');
	// const _tar = document.getElementById('main_nav');
	// const _el = _tar.querySelectorAll('span');
	// gsap.fromTo(_el,{y:60},{
	// 	y:0,
	// 	duration: 0.6,
	// 	ease: "power4.out",
	// 	delay: 0.4,
	// 	stagger: 0.05,
	// });
}
function nav_hide(){
	body.removeClass('navopen');
	body.addClass('navclose');
}
function navInit(){
	body.addClass('navclose');
	$('#navBtn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
	// window.addEventListener('scroll', function(){
	// 	nav_hide();
	// });
}

function navChange(){
	nav_hide();
	const namespace = $('[data-barba="container"]').attr('data-barba-namespace');
	$('#nav').attr('data-namespace', namespace);
}


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.set(_elmc,{ opacity:0, y:60 });
		gsap.to(_elmc,
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.out",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1),
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
	});
	body.addClass('scrltop');
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


function anchorLink(){
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		// e.stopPropagation();
		nav_hide();
		let hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		let currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}

function top_kv_video() {
	video_mute();
	videoSwitch();
}

function videoSwitch() {
	const _video = document.getElementById('top_kv_video');
	const videosp = '_sp.';
	const videopc = '_pc.';
	let videoState = 1;
	const switchsrc = () => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(videoState == 1){
				var srcname = _video.getAttribute('src').replace(videopc, videosp);
				_video.src = srcname;
				videoState = 0;	
			}
		}else{
			if(videoState == 0){
				var srcname = _video.getAttribute('src').replace(videosp, videopc);
				_video.src = srcname;
				videoState = 1;	
			}
		}
		_video.style.opacity = 1;
	}
	switchsrc();
	window.addEventListener("resize", () => switchsrc());
}

function video_mute() {
	const _video = document.getElementById('top_kv_video');
	const _play_btn = document.getElementById('play_btn');
	_play_btn.addEventListener('click', function(){
		if( _video.paused ){
			_video.play();
			_play_btn.classList.remove('pause');
		}else{
			_video.pause();
			_play_btn.classList.add('pause');
		}
	});
	const _sound_btn = document.getElementById('sound_btn');
	_sound_btn.addEventListener('click', function(){
		if( _video.muted ){
			_video.muted = false;
			_sound_btn.classList.remove('mute');
		}else{
			_video.muted = true;
			_sound_btn.classList.add('mute');
		}
	});
}


function seed_anime() {
	const seed_anime_svg = document.querySelectorAll('.seed_anime_svg');
	seed_anime_svg.forEach((svg, i) => {
		const _cir = svg.querySelector('#cir'),
		_dot = svg.querySelector('#dot'),
		_seed = svg.querySelector('#seed'),
		_arw = svg.querySelector('#arw'),
		_series = svg.querySelector('#series'),
		_light = svg.querySelector('#light'),
		_txt = svg.querySelectorAll('.txt'),
		_underline = svg.querySelector('#txt5 #Vector_49');

		gsap.set(_cir, {scale:0, transformOrigin:'0 50%'})
		gsap.set(_dot, {scale:0, transformOrigin:'50% 50%'})
		gsap.set(_seed, {x:-20, opacity:0})
		gsap.set(_arw, {x:-20, opacity:0})
		gsap.set(_series, {x:-20, opacity:0})
		gsap.set(_light, {scale:0, transformOrigin:'0 50%'})
		gsap.set(_txt, {x:-20, opacity:0})

		gsap.timeline({repeat:-1, repeatDelay:0.5})
		.to(_underline, { opacity:0.8, duration: 0.1, ease: "none"})
		.to(_underline, { opacity:0, duration: 0.1, ease: "none"}, '>0.5');
	
		const tl = gsap.timeline({paused:true,repeat:-1, repeatDelay:4.5})
		.to(_cir, {scale:1, transformOrigin:'0 50%', duration:0.8, ease: "power4.out"})
		.to(_dot, {scale:1, transformOrigin:'50% 50%', duration:0.8, ease: "power4.out"}, '<0.4')
		.to(_seed, {x:0, opacity:1, duration:0.8, ease: "power4.out"}, '<0.4')
		.to(_arw, {x:0, opacity:1, duration:0.8, ease: "power4.out"}, '<0.2')
		.to(_series, {x:0, opacity:1, duration:0.8, ease: "power4.out"}, '<0.2')
		.to(_light, {scale:1, transformOrigin:'0 50%', duration:0.8, ease: "power4.out"}, '<0.4')
		.to(_txt, {x:0, opacity:0.8, duration:0.8, ease: "power4.out", stagger:0.1}, '<0.4');
		
		const seedAnime = ScrollTrigger.create({
			trigger: '#top_seedanime',
			start: "top bottom-=20%",
			onEnter: self => {
				tl.play();
			},
		});
	
	});
}

function portfolio_swiper(){
	gsap.utils.toArray('.portfolio_blc').forEach(el => {
		const _tar = el.querySelector('.portfolio_swiper');
		const _prev = el.querySelector('.sprev');
		const _next = el.querySelector('.snext');
		const swiper = new Swiper(_tar, {
			slidesPerView: 3,
			spaceBetween: 10,
			speed: 600,
			navigation: {
				prevEl: _prev,
				nextEl: _next,
			},
			breakpoints: {
				767: {
					slidesPerView: 6,
					spaceBetween: 15,
				}
			},
		});	
	});
}


function top_insights_swiper(){
	const swiper = new Swiper('#top_insights_swiper', {
		effect: 'coverflow',
		coverflowEffect: {
			scale: 0.8,
			rotate: 0,
			slideShadows: false,
		},
		slidesPerView: 1.5,
		spaceBetween: 15,
		centeredSlides: true,
		speed: 600,
		loop: true,
		navigation: {
			prevEl: '#insight_prev',
			nextEl: '#insight_next',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			767: {
				slidesPerView: 2.5,
			}
		},
	});
}


function insights_swiper(){
	if( document.getElementById('insights_swiper') == null ){
		return;
	}
	const swiper = new Swiper('#insights_swiper', {
		slidesPerView: 1.4,
		spaceBetween: 15,
		centeredSlides: true,
		speed: 600,
		loop: true,
		navigation: {
			prevEl: '#insight_prev',
			nextEl: '#insight_next',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			767: {
				slidesPerView: 3,
				spaceBetween: 20,
				centeredSlides: false,
			}
		},
	});
}


function about_line_logo(){
	const _tar = document.getElementById('line_logo');
	const _path = _tar.querySelectorAll('path');
	const tl = gsap.timeline({
		defaults: {
			duration: 0.4,
		},
		scrollTrigger: {
			trigger: _tar,
			start: "top bottom",
			toggleActions: "play none play reverse",
			once: true,
		}
	})
	.fromTo(_path, {drawSVG:'0%'}, {drawSVG:'100%', stagger: 0.02})
}


function office_swiper(){
	const thumb_swiper = new Swiper('#office_swiper_thumb', {
		slidesPerView: 3,
		spaceBetween: 5,
		speed: 600,
		loop: true,
		breakpoints: {
			767: {
				slidesPerView: 6,
			}
		},
	});
	const swiper = new Swiper('#office_swiper', {
		slidesPerView: 1,
		spaceBetween: 15,
		speed: 600,
		loop: true,
		thumbs: {
			swiper: thumb_swiper,
		},
	});
}


function teamitemFunc(){
	const swiper = new Swiper('#team_swiper', {
		slidesPerView: 1,
		spaceBetween: 15,
		speed: 600,
		navigation: {
			prevEl: '#team_prev',
			nextEl: '#team_next',
		},
	});

	const _modalbtn = document.querySelectorAll('.modal_btn');
	_modalbtn.forEach((_tar, i) => {
		_tar.addEventListener('click', function(){
			const itemNum = _tar.getAttribute('data-item-num');
			const slideId = _tar.getAttribute('data-slide-id');
			const slideIndex = Array.from(document.querySelectorAll('.swiper-slide')).findIndex(slide => slide.id === slideId);
			if (slideIndex !== -1) {
				swiper.slideTo(slideIndex, 0);
			}
			$('.modal').addClass('active');
		});
	});
	$('.modalbg, .modal_close').on('click', function(){
		$('.modal').removeClass('active');
	});
}


function portfolio_list(){
	var grid = new Muuri('#portfolio_list');
	const sortbtn = document.querySelectorAll('.sortbtn');
	sortbtn.forEach(btn => {
		btn.addEventListener('click', function(){
			$('.sortbtn').removeClass('active');
			this.classList.add('active');
			const sortValue = this.getAttribute('data-sort');
			if(sortValue == 'all'){
				grid.filter('.item');
			}else{
				grid.filter(`.${sortValue}`);
			}
		});
	});
}


function copyURL(){
	$('.copyurl').on('click', function(){
		const url = location.href;
		navigator.clipboard.writeText(url).then(
			()=>{alert("クリップボードにコピーしました")},
			()=>{alert("コピーに失敗しました")}
	 );
	});
}


function contactModal(){
	const _modalbtn = document.querySelectorAll('.modal_btn');
	_modalbtn.forEach((_tar, i) => {
		_tar.addEventListener('click', function(){
			const formId = _tar.getAttribute('data-form-id');
			$('#'+formId).addClass('active');
		});
	});
	$('.modalbg, .modal_close').on('click', function(){
		$('.modal').removeClass('active');
	});
}


function community_swiper(){
	const swiper = new Swiper('#community_swiper', {
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 600,
		loop: true,
		autoplay: {
			delay: 4000,
		},
	});
}

function search_btn_expand() {
	const _btn = document.getElementById('search_ex');
	const _searchbox = document.querySelector('.searchbox');
	_btn.addEventListener('click', function(){
		_searchbox.classList.add('expand');
	});
}


function expandFunc() {
	const expand = (parent, state) => {
		if(state){
			parent.classList.add('open');
		}else{
			parent.classList.remove('open');
		}
		const exc = parent.querySelector('.exc');
		gsap.utils.toArray(exc).forEach(ex => {
			gsap.to(ex,{
				height: (!state) ? 0 : "auto",
				duration:0.3,
				ease: "power2.inOut",
				onComplete: () => {
					ScrollTrigger.refresh();
				}
			});
		});
	}
	const expand_event = (e) => {
		let parent = e.target.closest('.ex');
		const result = parent.classList.contains('open');
		if (result) {
			expand(parent, false);
		}else{
			expand(parent, true);
		}
	};
	gsap.utils.toArray('.exbtn').forEach(exbtn => {
		let parent = exbtn.closest('.ex');
		expand(parent, false);
		exbtn.removeEventListener('click',expand_event);
		exbtn.addEventListener('click',expand_event);
	});
}


const contact_Cf7 = () => {
	if (wpcf7 && typeof wpcf7.init === 'function') { 
		document.querySelectorAll( '.wpcf7 > form' ).forEach( function( form ) {
			wpcf7.init( form );
		});
	}
}


var PageEvents = {
	loadFunc: function(){
		navInit();
		copyURL();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		anchorLink();
		// navChange();
		insights_swiper();
		OBSERVER.observeEvents(1);
	},
	afterFunc: function(){
		elFunc();
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		seed_anime();
		portfolio_swiper();
		top_insights_swiper();
		top_kv_video();
	},
	aboutFunc: function(){
		about_line_logo();
		office_swiper();
	},
	teamFunc: function(){
		teamitemFunc();
	},
	portfolioFunc: function(){
		portfolio_list();
	},
	insightsFunc: function(){
		search_btn_expand();
	},
	news_archiveFunc: function(){
		body.addClass('news_archive');
	},
	contactFunc: function(){
		contactModal();
	},
	communityFunc: function(){
		community_swiper();
	},
	careersFunc: function(){
		expandFunc();
		contactModal();
	},
};

const replaceBogo = function(data){
	let target = '#wrapper';
	if(data){
		target = data.next.html;
	}
	const $newPageBogo = $(target).find('.bogo-language-switcher').html();
	gsap.utils.toArray('.bogo').forEach(bogo => {
		bogo.innerHTML = $newPageBogo;
		bogo.querySelectorAll('a').forEach(a => {
			a.classList.add('nopj');
		});
	});
}

function pjaxSettings(){

	barba.use(barbaPrefetch);

	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if (href == null) {
				href = el.getAttribute('xlink:href');
		}
		if (el.classList.contains('nopj')) {
				return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
				el.setAttribute('target', '_blank');
				return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/, "");
		if (href.startsWith(location.protocol + '//' + location.host)) {
				if (href.indexOf('#') > -1 && extract_hash == url) {
						return false;
				}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
				el.setAttribute('target', '_blank');
				return true;
		}
		return false;
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						body.addClass('scrldown');
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0, scrollTo:scrltarget, ease:'power2', onComplete:function(){
					body.removeClass('scrldown');
					resolve();
				}});
			}
		})
	}


	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('#footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown scrltop scrl onevh news_archive');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});

	barba.hooks.after((data) => {
		scroll(data);
		async function callerFun(){
			pageCheck();
			if( document.querySelectorAll('wpcf7') ){
				contact_Cf7();
			}	
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onStart:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

async function pageCheck() {
	await new Promise((resolve, reject) => {
		PageEvents.commonFunc();
		if( document.getElementById('top') ){
			PageEvents.topFunc();
		}else{
			body.addClass('inpage');
		}
		if( document.getElementById('aboutus') ){
			PageEvents.aboutFunc();
		}
		if( document.getElementById('team') ){
			PageEvents.teamFunc();
		}
		if( document.getElementById('portfolio') ){
			PageEvents.portfolioFunc();
		}
		if( document.getElementById('insights_archive') ){
			PageEvents.insightsFunc();
		}
		if( document.getElementById('news_archive') ){
			PageEvents.news_archiveFunc();
		}
		if( document.getElementById('contact') ){
			PageEvents.contactFunc();
		}
		if( document.querySelectorAll('.community_page') ){
			PageEvents.communityFunc();
		}
		if( document.getElementById('careers') ){
			PageEvents.careersFunc();
		}
		PageEvents.afterFunc();
		body.addClass('ready');
		resolve();
	})
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
	window.addEventListener('load', function(){
		body.addClass('siteloaded');
	});
}

initialize();

})();
})( jQuery );

}